import * as Sentry from '@sentry/react-native';

export const SentryHelper = {
  setUser: (id, employerId, name, deviceId = '') => {
    const userInfo = { id: id?.toString() ?? '', username: name, segment: employerId?.toString() ?? '' };
    if (deviceId !== '') {
      userInfo.deviceId = deviceId;
    }
    Sentry.setUser(userInfo);
  },
  captureException: exception => {
    if (exception && exception.length > 0) Sentry.captureException(exception[0]);
  },
  captureCustomException: (name = null, params = null) => {
    if (name === null || params === null) return;
    Sentry.captureException(new Error(name), {
      tags: {
        ...params,
      },
    });
  },
  clearUser: () => {
    // Call During logout
    Sentry.configureScope(scope => scope.setUser(null));
  },
  captureMessage: sMsg => {
    Sentry.captureMessage(sMsg);
  },
};

export function beforeBreadCrumbEvent() {
  return (breadcrumb, hint) => {
    if (breadcrumb.category === 'xhr') {
      const data = {
        requestHeader: hint?.xhr?.__sentry_xhr_v3__?.request_headers,
        requestBody: hint?.xhr?.__sentry_xhr_v3__?.body,
        response: hint?.xhr?.response,
        responseUrl: hint?.xhr?.responseURL,
      };
      return { ...breadcrumb, data };
    }
    return breadcrumb;
  };
}
